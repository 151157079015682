<template>
  <base-section
    id="slogan-training"
    class="pt-10 pb-10"
  >
    <v-container class="fill-height px-0 py-0">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="1920"
          width="100%"
        >
          <v-row>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12"
            >
              <v-img
                :src="require('@/assets/Ronghao_logo_appbar.png')"
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 150"
                contain
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12"
            >
              <v-responsive
                class="d-flex align-center mx-auto"
                height="100%"
                max-width="1800"
                width="100%"
              >
                <base-heading
                  size="text-h3"
                  weight="bold"
                  title="RH ROBOTICS"
                  mobileSize="text-h4"
                  class="text-center primary--text"
                />
                <base-subheading
                  size="text-h4"
                  weight="bold"
                  title="ACCELERATOR PROGRAM"
                  mobileSize="text-h5"
                  class="text-center primary--text"
                />
                <base-subheading
                  size="text-h5"
                  weight="bold"
                  title="Talent Development & Employment Training Program"
                  mobileSize="text-h6"
                  class="text-center secondary--text"
                />
              </v-responsive>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSloganTraining',
    methods: {
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
